<template>
  <div>
    <div class="" v-if="lang=='in'">
        <survey-form-india :surveyId="survey_id" :userId="user_id" :surveyChannel="survey_channel" />  
    </div>
    <div v-else>
      <div v-if="lang == 'zh'">
        <survey-form :surveyId="survey_id" :userId="user_id" :surveyChannel="survey_channel" />  
      </div>
      <div v-else>
        <survey-form-english :surveyId="survey_id" :userId="user_id" :surveyChannel="survey_channel" />  
      </div>
    </div>
  </div>
</template>

<script>
import SurveyForm from '@/components/SurveyForm.vue'
import SurveyFormIndia from '@/components/SurveyFormIndia.vue'
import SurveyFormEnglish from '@/components/SurveyFormEnglish.vue'
import Cookies from 'js-cookie';

export default {
  name: 'HomeView',
  components: {
    SurveyForm,
    SurveyFormIndia,
    SurveyFormEnglish
  },
  data() {
    return {
      survey_id: 'notfound',
      user_id: '',
      survey_channel: 'notfound'
    };
  },
  created() {
    const parames = this.$route.query;
    this.survey_id = parames.qs;
    this.lang = parames.lang;
    this.user_id = this.getUserCookie();
    this.survey_channel = parames.qch;
  },
  methods: {
    getUserCookie() {
      if (! this.getCookieByName('user_id')) {
        const userId =  "U"+ Math.floor(1000 + Math.random() * 9000) + "-" + (new Date().getTime());
        Cookies.set('user_id', userId, { expires: 30 });
      }
      return this.getCookieByName('user_id');
    },
    getTimestamp() {
      return (new Date().getTime());
    },
    getCookieByName(name) {
      return Cookies.get(name);
    },
  }
}
</script>