import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import i18n from './i18n'; // import i18n instance
import App from './App';
import router from './router';
import 'ant-design-vue/dist/reset.css';

import { getEndpoint } from './helper/utils';


const app = createApp(App);
app.config.globalProperties.$getEndpoint = getEndpoint;

app.use(i18n).use(Antd).use(router).mount('#app');