import { createI18n } from 'vue-i18n';

// Messages in different languages
const messages = {
  en: {
    message: {
      error: 'Error​',
      channelNotFound: 'The channel does not exist.',
      open_ans: 'Free-Form Answers',
      multiple_ans: 'Multiple Choice',
      single_ans: 'Single Choice',
      personal_info: 'Personal Information',
      fill_basic_info: 'Please complete the basic information section for the respondent',
      pls_enter_name:"Please enter your name or nickname.",
      pls_enter_email:"Please provide your email address.",
      pls_enter_wechat:"Enter your WeChat ID.",
      pls_enter_phone:"Enter your mobile phone number.",
      name:'Name',
      email_error:'Please enter your email',
      email:'Email Address',
      wechat_id:'WeChat ID',
      phone:'Mobile Phone Number',
      submit: 'Submit',
      finished: 'Complete',
      thank_for_your_time: 'Thank you for your valuable time.',
      please_answer_all_question: 'Please answer all questions!',
      please_fill_the_required : 'Please fill in the required information!',
      please_enter_name: 'Please enter your name.',
      please_enter_phone: 'Please enter your mobile phone number.',
      please_enter_wechate: 'Please enter your WeChat ID.',
      okay: 'Okay',
      you_have_already_participated_do_not_do_it_again: 'You have already participated in the survey, please do not participate again!',
    }
  },
  zh: {
    message: {
      error: '失败',
      channelNotFound: '调研渠道不存在',
      open_ans: '开放式问题',
      multiple_ans: '多选',
      single_ans: '单选',
      personal_info: '个人信息',
      fill_basic_info: '问卷人基础信息填写',
      pls_enter_name: '填写您的姓名或昵称',
      pls_enter_email: '请填写您的邮箱',
      pls_enter_wechat: '填写您的微信号',
      pls_enter_phone: '填写您的手机号',
      name:'姓名',
      email_error:'请输入您的邮箱',
      email:'邮箱',
      wechat_id:'微信号',
      phone:'手机号',
      submit: '提交',
      finished: '完成',
      thank_for_your_time: '感谢您的宝贵时间',
      please_answer_all_question: '请您回答所有问题！',
      please_fill_the_required : '请填写所需的内容 ！',
      please_enter_name: '请输入您的姓名',
      please_enter_phone: '请输入您的手机号',
      please_enter_wechate: '请输入您的微信号',
      okay: '好的',
      you_have_already_participated_do_not_do_it_again: '您已参与过问卷调查，请勿重复参与！',
    }
  },
  in: {
    message: {
      error: 'त्रुटि',
      channelNotFound: 'यह चैनल मौजूद नहीं है।',
      open_ans: 'मुक्त-रूप उत्तर',
      multiple_ans: 'बहुविकल्पी',
      single_ans: 'एकल विकल्प',
      personal_info: 'व्यक्तिगत जानकारी',
      fill_basic_info: 'कृ पया उत्तरदाता के लिए बुनियादी सूचना अनुभाग पूरा करें',
      pls_enter_name:"कृपया अपना नाम या उपनाम दर्ज करें",
      pls_enter_email:"कृपया अपना ईमेल पता प्रदान करें",
      pls_enter_wechat:"Enter your WeChat ID.",
      pls_enter_phone:"अपना मोबाइल फोन नंबर दर्ज करें",
      name:'नाम',
      email_error:'कृपया अपना ईमेल दर्ज करें',
      email:'ई-मेल पता',
      wechat_id:'WeChat ID',
      phone:'मोबाइल/फोन नंबर',
      submit: 'जमा करें',
      finished: 'पूरा करें',
      thank_for_your_time: 'आपके कीमती समय के लिए धन्यवाद।',
      please_answer_all_question: 'कृपया सभी प्रश्नों का उत्तर दें!',
      please_fill_the_required : 'कृपया आवश्यक जानकारी भरें!',
      please_enter_name: 'कृपया अपना नाम दर्ज करें',
      please_enter_phone: 'कृपया अपना मोबाइल फोन नंबर दर्ज करें',
      please_enter_wechate: 'Please enter your WeChat ID.',
      okay: 'ठीक है',
      you_have_already_participated_do_not_do_it_again: 'आप पहले ही सर्वेक्षण में भाग ले चुके हैं, कृपया फिर से भाग न लें!',
    }
  },
  // Add other languages here
};

const i18n = createI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
});

export default i18n;


