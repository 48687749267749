<template>
  <a-result status="warning" :title="title">
  </a-result>
</template>

<script>

export default {
  name: 'SurveyNotFound',
  props: {
    title: String,
  },
  components: {
    
  },
  data() {
    return {
      survey_id: '',
      user_id: '',
      survey_channel: ''
    };
  },
  created() {

  },
  methods: {

  }
}
</script>