<template>
   <a-result
    status="success"
    title="完成"
    subTitle="谢谢您的宝贵时间"
  >
  </a-result>
</template>

<script>

export default {
  name: 'SuccessView',
  components: {
    
  },
  data() {
    return {
      survey_id: '',
      user_id: '',
      survey_channel: ''
    };
  },
  created() {


  },
  methods: {
   
  }
}
</script>