import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SuccessView from '../views/SuccessView.vue'
import SurveyNotFound from '../views/SurveyNotFound.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/success',
    name: 'sucess',
    component: SuccessView
  },
  {
    path: '/survey-not-found',
    name: 'notfound',
    component: SurveyNotFound,
    props: { title: '找不到网页'}
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
